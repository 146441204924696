<template>
    <div>
        <auth-layout>
            <slot></slot>
            <vue-recaptcha
                ref="recaptcha" @verify="onCaptchaVerified"
                size="invisible" sitekey="6LelIscaAAAAAA0ODBaTFs_FUqS3WTgy-G0bP1pG"
            />
            <div class="row vh-100 overflow-x-hidden">
                <div class="col-12 bg-white rounded-lg mr-0 mr-md-minus-5px z-index-1 shadow">
                    <div class="d-flex align-items-start flex-column h-100">
                        <div class="my-auto w-100">
                            <div class="row justify-content-center">
                                <div class="col-10 col-sm-7 col-md-5 col-lg-8 col-xl-7 col-xxl-6 login-wrapper py-5">
                                    <div class="mx-auto login-w-limit">
                                        <div class="logo-wrapper text-center mb-2 mb-lg-5 pb-2" style="margin-right: 50px;">
                                            <img src="@/assets/img/bau-logo-umis.png" height="175"/>
                                        </div>
                                       <h4 class="text-center mb-5 d-block d-lg-none">{{ $t('student_information_system') }}</h4>
                                        <div class="fadeIn">
                                            <login v-if="!token" ref="login" @captcha="onCaptcha" @loggedIn="getToken" from="oauth"></login>
                                            <b-alert v-else-if="!service && errors" variant="danger" show>
                                                <li v-for="error in errors">
                                                    {{ error[0] }}
                                                </li>
                                            </b-alert>
                                            <confirm-view v-else :service="service"></confirm-view>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <language-switch/>
                    </div>
                </div>
            </div>
        </auth-layout>
    </div>
</template>

<script>
import Base from '@/plugins/Base'
import OAuthService from '@/services/OAuthService'
import AuthLayout from '@/layouts/AuthLayout'
import LanguageSwitch from './LanguageSwitch'
import ConfirmView from './Confirmation'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import { VueRecaptcha } from 'vue-recaptcha'
import qs from 'qs'
import Login from "@/modules/auth/pages/Login.vue";

export default {
    components: {
        Login,
        AuthLayout,
        LanguageSwitch,
        ConfirmView,
        ValidationProvider,
        ValidationObserver,
        VueRecaptcha
    },
    metaInfo() {
        return {
            title: this.$t('login')
        }
    },
    data() {
        return {
            token: null,
            service: null,
            errors: null
        }
    },
    watch: {
        timerEnabled(value) {
            if (value) {
                setTimeout(() => {
                    this.timerCount--
                }, 1000)
            }
        },
        timerCount: {
            handler(value) {
                if (value > 0 && this.timerEnabled) {
                    setTimeout(() => {
                        this.timerCount--
                    }, 1000)
                }
                else if (value > 0 && !this.timerEnabled) {
                    this.alertShow = false
                }
                else {
                    this.alertVariant = 'danger'
                    this.alertShow = true
                }
            },
            immediate: true
        },
        token(value) {
            if(value) {
                const config = {
                    params: {
                        client_id: this.$route?.query?.client_id,
                        redirect_uri: this.$route?.query?.redirect_uri
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }

                OAuthService.getAuthorizeForm(config)
                    .then(response => {
                        this.service = response.data.data
                    })
                    .catch(error => {
                        if (error.status == 422) {
                            this.errors = error.data.errors
                        }
                    })
            }
        }
    },
    created() {
        this.getToken()
    },
    methods: {
        onCaptcha() {
            this.$refs.recaptcha.execute()
        },
        onCaptchaVerified (recaptchaToken) {
            this.$refs.login.onCaptchaVerified(recaptchaToken)
            this.$refs.recaptcha.reset()
        },
        getToken() {
            let tokenExpiresIn = Base.LocalStorage.get('access_token_expires_in')
            let date = new Date();
            if(date.getTime() > tokenExpiresIn){
                return;
            }
            this.token = Base.LocalStorage.get('access_token')
        }
    }
}
</script>
