<template>
    <div class="row">
        <div class="col-12">
            <h5 v-if="service">
                {{ $t('oauth_confirmation_text', {
                    client: service.client_name
                }) }}
            </h5>
        </div>
        <div class="col-5">
            <b-button
                @click="$router.push('/dashboard')"
                type="submit"
                variant="danger"
                class="btn-lg btn-block d-flex justify-content-between align-items-center"
            >
                {{ $t('decline').toUpper() }}
            </b-button>
        </div>
        <div class="col-7">
            <b-button
                @click="authorize"
                type="submit"
                variant="primary"
                class="btn-lg btn-block d-flex justify-content-between align-items-center"
            >
                {{ $t('confirm').toUpper() }}
                <i class="ri-arrow-right-line"></i>
            </b-button>
        </div>
    </div>
</template>

<script>
import OAuthService from '@/services/OAuthService'
import qs from 'qs'

export default {
    props: {
        service: {
            type: Object
        }
    },
    methods: {
        authorize() {
            const config = {
                params: {
                    client_id: this.$route?.query?.client_id,
                    redirect_uri: this.$route?.query?.redirect_uri
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }

            OAuthService.authorize(config)
                .then(resp => {
                    const response = resp.data.data
                    let redirectUri = response.redirect_uri + location.search + '&code=' + response.code
                    window.location.href = redirectUri
                })
        }
    }
}
</script>
