import Api from '@/services/Index'

const getAuthorizeForm = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_AUTH
    return Api.get('/oauth/authorize-form', config)
}

const authorize = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_AUTH
    return Api.get('/oauth/authorize', config)
}

export default {
    getAuthorizeForm,
    authorize
}
